.taskdetail {
  padding-top: var(--space-lg);

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &__wrapper {
      position: relative;
      box-shadow: var(--box-shadow);  
      border-radius: 8px;
      overflow: hidden;
      
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: calc(9% / 16%) * 100%;
      }
    }
  }
}