.taskitem {
  @extend .card !optional;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  
  margin-bottom: var(--space-md);

  overflow: hidden;

  &.unlocked {
    border-color: var(--color-accent);
  }

  &__content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding-right: var(--space-lg);
    // align-items: center;
  }

  &__data{
    flex: 1;
    padding-left: var(--space-lg);
    display: flex;
    flex-flow: row nowrap;
  }

  &__title {
    
    margin: 0;
    font-weight: bold;
    font-size: var(--fontsize-md);

    
  }
  &__time {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--space-lg);
    padding-top: var(--space-md);
  }
  &__reward {
    font-weight: bold;
    font-size: var(--fontsize-xl);
    
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &__wrapper {
      position: relative;
      min-width: 64px;
      min-height: 64px;
      width: 30vw;
      max-width: 200px;
      max-height: 200px;


      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
        background-color: lightgray;
      }
    }
    
  }
}