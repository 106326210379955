:root {
--fontsize-xxxs: 8px;
--fontsize-xxs: 10px;
--fontsize-xs: 12px;
--fontsize-sm: 14px;
--fontsize-md: 16px;
--fontsize-lg: 20px;
--fontsize-xl: 24px;
--fontsize-xxl: 30px;
--fontsize-xxxl: 36px;
}
$fontsize-xxxs: var(--fontsize-xxxs);
$fontsize-xxs: var(--fontsize-xxs);
$fontsize-xs: var(--fontsize-xs);
$fontsize-sm: var(--fontsize-sm);
$fontsize-md: var(--fontsize-md);
$fontsize-lg: var(--fontsize-lg);
$fontsize-xl: var(--fontsize-xl);
$fontsize-xxl: var(--fontsize-xxl);
$fontsize-xxxl: var(--fontsize-xxxl);
