.select{
  width: 100%;
  

  label {
    display: block;
    font-weight: 600;
    margin-bottom: var(--space-sm);
  }

  &__inner {
    position: relative;
    border: solid 1px rgba(var(--color-text--rgb), .1);
    border-radius: 8px;


  }

  &__value {
    position: absolute;
    top: 50%;
    left: var(--space-lg);
    transform: translateY(-50%);
    pointer-events: none;
  }

  select {
    width: 100%;
    height: 100%;
    padding: var(--space-md) var(--space-lg);
    display: block;
    opacity: 0; 
  }

  &.disabled {
    label {
      opacity: .4;
    }
    select {
      border-color: rgba(var(--color-text--rgb), .4);
    }
  }
}