:root {
--space-xxxs: 0px;
--space-xxs: 0px;
--space-xs: 2px;
--space-sm: 4px;
--space-md: 8px;
--space-lg: 16px;
--space-xl: 32px;
--space-xxl: 64px;
--space-xxxl: 96px;
}
$space-xxxs: var(--space-xxxs);
$space-xxs: var(--space-xxs);
$space-xs: var(--space-xs);
$space-sm: var(--space-sm);
$space-md: var(--space-md);
$space-lg: var(--space-lg);
$space-xl: var(--space-xl);
$space-xxl: var(--space-xxl);
$space-xxxl: var(--space-xxxl);
