.btn{
  padding: var(--space-md) var(--space-lg);
  border-radius: 1000px;
  border: none;
  color: var(--color-text-light);
  cursor: pointer;
  transition: background-color .3s ease;

  &[disabled] {
    background-color: rgba(var(--color-text--rgb), .2);
  }

  &--normal{
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primaryDark);
    }
  }
  &--dark{
    background-color: var(--color-primaryDark);

    &:hover {
      background-color: var(--color-primary);
    }
  }
  &--accent{
    background-color: var(--color-accent);

    &:hover {
      background-color: var(--color-accentDark);
    }
  }
  &--link{
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    color: var(--color-accent);

    &:hover {
      color: var(--color-accentDark);
    }
  }
}