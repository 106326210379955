:root {
--color-primary--rgb: 96, 230, 190;
--color-primary: rgb(96, 230, 190);
--color-secondaryLight--rgb: 255, 255, 255;
--color-secondaryLight: rgb(255, 255, 255);
--color-secondary--rgb: 255, 255, 255;
--color-secondary: rgb(255, 255, 255);
--color-secondaryDark--rgb: 255, 255, 255;
--color-secondaryDark: rgb(255, 255, 255);
--color-accentLight--rgb: 255, 150, 79;
--color-accentLight: rgb(255, 150, 79);
--color-accent--rgb: 255, 150, 79;
--color-accent: rgb(255, 150, 79);
--color-text--rgb: 0, 0, 0;
--color-text: rgb(0, 0, 0);
--color-background--rgb: 255, 255, 255;
--color-background: rgb(255, 255, 255);
--color-primaryLight--rgb: 191, 238, 225;
--color-primaryLight: rgb(191, 238, 225);
--color-primaryDark--rgb: 18, 69, 63;
--color-primaryDark: rgb(18, 69, 63);
--color-accentDark--rgb: 167, 67, 0;
--color-accentDark: rgb(167, 67, 0);
--color-text-light--rgb: 255, 255, 255;
--color-text-light: rgb(255, 255, 255);
}
