.react-calendar {
  &__tile {
    &--now {
      background-color: var(--color-primary) !important;
    }
    &--active{
      background-color: var(--color-primaryDark) !important;
    }
   
  }

  &__month-view__days__day{
    &--weekend {
      color: var(--color-text--rgb) !important;
    }
  }
}