body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-y: contain;
  
  height: 100vh;

  font-size: var(--fontsize-sm);

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p{
    margin: 0;
  }

}

#root {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  height: 100%;
}

.app {

  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  height: 100vh;
  overflow: hidden;

  &__content {
    padding: 0 var(--space-lg);
    margin: auto;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    
    max-width: 1200px;
  }
}

@media not all and (min-resolution:.001dpcm) { 
  body, #root, .app{
    height: -webkit-fill-available;
  }
}


