.highlight {
  z-index: 0;
  position: relative;
  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    width: calc(100% - var(--space-md));
    height: 15px;
    background-color: var(--color-primary);
    bottom: 0;
    right: 0;
    // opacity: .6;
  }

  &--accent{
    &:before{
      background-color: var(--color-accent);
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  &.highlight {
    padding: unset;
  }
}