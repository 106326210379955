.rewarditem {
  @extend .card !optional;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 0 var(--space-md) 0;
  width: 320px;
  flex-shrink: 0;
  margin-right: var(--space-lg);
  // box-shadow: var(--color-box-shadow;
  
  overflow: visible;
  margin-bottom: var(--space-lg);
  
  
  &.unlocked {
    border-color: var(--color-accent);
  }

  &__content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    padding: var(--space-md);
  }

  &__issuer {
    padding: var(--space-md) 0;
    font-size: var(--fontsize-md);
    font-weight: bold;
  }
  &__description {
    flex: 1;
  }


  &__cost {
    font-weight: bold;
    font-size: var(--fontsize-xl);
    &__wrapper {
      padding-top: var(--space-xl);
      display: flex;
      justify-content: flex-end;
    }
  }

  &--card {
    .rewarditem {
    
      &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        &__wrapper {
          position: relative;
          width: 100%;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          overflow: hidden;
    
          &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: calc(9% / 16%) * 100%;
            background-color: lightgray;
          }
        }
      }
    }
  }

  &--row {
    width: 100%;
    
    padding: var(--space-lg);
    align-items: flex-start;
    .rewarditem {

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &__wrapper{
          height: 64px;
          width: 64px;
          border-radius: 32px;
          overflow: hidden;

        }
      }

      &__issuer {
        padding: 0;
      }
    }
  }
}