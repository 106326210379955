.footer {
  padding: var(--space-md) var(--space-lg);
  background-color: var(--color-background);

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  height: 56px;
  border-top: solid 1px var(--card-border);
  &__item {
    display: flex;
    flex: 1;
    
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
  }
}