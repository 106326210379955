.textinput {
  width: 100%;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: var(--space-sm);
  }

  input {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: solid 1px var(--color-primaryLight);
    padding: var(--space-md) var(--space-lg);
    outline-color: var(--color-primary);
    outline-width: 30px;
    
    &:focus {
      border-color: var(--color-primary);
    }
  }

  &.disabled {
    label {
      opacity: .4;
    }
    input {
      border-color: rgba(var(--color-text--rgb), .4);
    }
  }
}