.list {
  list-style: none;
  padding-inline-start: 0;

  li {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
    &:not(:last-of-type){
      border-bottom: solid 1px  rgba(var(--color-text--rgb), .2);
    }

    a{
      text-decoration: none;
      color: var(--color-text);
    }
  }
}