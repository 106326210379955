.modal {
  $ctx: '.modal';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
  transition: background-color .3s ease;

  &.show {
    pointer-events: all;
    background-color: rgba(0, 0, 0, .2);

    #{$ctx} {
      &__body {
        top: var(--space-xl) !important;
      }
    }
  }

  &__body {
    position: relative;
    background-color: var(--color-secondary);
    top: 100%;
    width: 100%;
    
    height: calc(100% - var(--space-xl));
    padding: var(--space-lg);
    transition: top .5s ease, box-shadow .5s ease;
    box-shadow: 0 -3px 12px 3px rgba(0, 0, 0, .4);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }

  &--camera {
    &.show {
      #{$ctx} {
        &__body{
          
          padding: 0;
         
        }
      }
    }
   
  }
}